@import '../../styles/index';

.login-headline {
  margin-bottom: 2rem;

  @include media-from(xs) {
    margin-bottom: 2.25rem;
  }

  & h2 {
    font-size: 2.25rem;
    margin-bottom: 0.5rem;

    @include media-from(xs) {
      font-size: 2.75rem;
    }
  }

  > div {
    font-size: 1.125rem;
  }
}

.formfield {
  min-width: calc(100vw - 3rem);
  width: 100%;

  @include media-from(xs) {
    min-width: unset;
    width: 22.875rem;
  }

  &--email {
    margin-bottom: 0;
  }

  &--password {
    padding-top: 1rem;
  }
}

.link {
  color: $surrogate-9;
  font-size: 1rem;
  font-weight: 400;
  line-height: 2rem;
  margin-bottom: 1.875rem;
  margin-top: 0.875rem;
  text-align: center;
  user-select: none;
  width: 100%;
}

.register {
  margin: 0 auto;
}

.button {
  margin-bottom: 0.25rem;
  min-width: 11.8125rem;
}

.flex-center {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.divider {
  margin: 1.75rem 0;

  @include media-from(xs) {
    margin: 2.75rem 0;
  }
}

.text {
  margin-bottom: 1rem;
  text-align: center;

  > span {
    color: $neutral-9;
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 30px;
    margin-bottom: 0.25rem;

    @include media-from(xs) {
      text-align: left;
    }
  }
}

.form {
  width: 100%;
}

.headline {
  margin-bottom: 0.875rem;
}

.info-banner {
  margin-bottom: 2rem;
}
