@import '../../styles/index';

.info-text {
  color: $neutral-9;
  font-size: 1.125rem;
  line-height: 26px;
  font-weight: 400;
  text-align: center;
}

.base {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.text {
  font-size: 1.125rem;
  line-height: 1.75rem;
  text-align: center;
  font-weight: 400;
  margin-bottom: 1.5rem;

  @include media-from(xs) {
    font-size: 1.25rem;
    line-height: 2rem;
    margin-bottom: 2rem;
  }
}

.teaser-box {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 2rem;

  @include media-from(xs) {
    flex-direction: row;
  }

  > .teaser-tile-box:first-child {
    @include media-from(xs) {
      margin-bottom: 0;
      margin-right: 2rem;
    }
  }

  > .teaser-tile-box > a:first-child {
    margin-bottom: 1rem;
    margin-top: 0;

    @include media-from(xs) {
      margin-top: 1rem;
    }
  }
}

.teaser-tile-box {
  min-height: 267px;
  width: 100%;
  border-radius: $border-radius-default;
  background-color: $neutral-0;
  box-shadow: 0 0 27px 0 rgba(0, 0, 0, 0.12);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  margin-bottom: 1.5rem;
  padding: 1.75rem 1.5rem 1rem;
  transition: transform $duration-default ease-in-out;

  &:hover {
    transform: translateY(-4px);
  }

  @include media-to(xs) {
    &:last-child {
      margin-bottom: 0;
    }
  }

  @include media-from(xs) {
    margin-right: 1.5rem;
    margin-bottom: 0;
    max-width: 267px;

    &:last-child {
      margin-right: 0;
    }
  }

  @include media-from(sm) {
    width: 267px;
  }

  & .box-headline {
    color: $neutral-12;
    font-size: 1.375rem;
    font-weight: bold;
    line-height: 1.35;
    text-align: center;
    margin: 0 0 1rem 0;
  }

  & .box-text {
    color: $neutral-9;
    font-size: 1rem;
    line-height: 1.5;
    text-align: center;
    margin-bottom: 1.75rem;
  }

  & .box-button {
    margin-top: auto;
    width: 100%;
  }
}

.headline {
  margin-bottom: 0.125rem;
}

.mail {
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.6;
  margin-bottom: 1.5rem;

  @include media-from(xs) {
    margin-bottom: 2rem;
  }
}

.button {
  &:hover {
    transform: translateY(0);
  }
}
